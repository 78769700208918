// WhyChooseUsSection.js
import React from 'react';
import './WhyChooseUsSection.css';

const WhyChooseUsSection = () => {
  const reasons = [
    {
      title: 'Innovative Solutions',
      description: 'We provide cutting-edge and innovative solutions to meet your business needs.',
    },
    {
      title: 'Global Presence',
      description: 'With a global presence, we offer services and support around the clock.',
    },
    {
      title: 'Customer-Centric Approach',
      description: 'Our customer-centric approach ensures your satisfaction is our top priority.',
    },
    {
      title: 'Security and Reliability',
      description: 'We prioritize the security and reliability of our services to build trust.',
    },
  ];

  return (
    <section className="why-choose-us-section">
      <div className="content">
        <h2>Why Choose Us</h2>
        <div className="reasons-container">
          {reasons.map((reason, index) => (
            <div className="reason" key={index}>
              <h3>{reason.title}</h3>
              <p>{reason.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;
