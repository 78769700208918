import React from 'react';
import './CustomizedAppsPricing.css';
import { Link } from 'react-router-dom';


const AppDevelopmentPricing = () => {
    const appTypes = [
      {
        type: 'E-Commerce App',
        price: 'INR 20,000 - 30,000',
      },
      {
        type: 'Social Networking App',
        price: 'INR 30,000 - 50,000',
      },
      {
        type: 'Food Delivery App',
        price: 'INR 20,000 - 30,000',
      },
      {
        type: 'Fitness Tracking App',
        price: 'INR 40,000 - 60,000',
      },
      {
        type: 'Travel Booking App',
        price: 'INR 20,000 - 30,000',
      },
      {
        type: 'Educational App',
        price: 'INR 20,000 - 30,000',
      },
    ];
  
    return (
      <div className="customized-apps-pricing-page">
        <div className="pricing-heading">
          <h2>App Development Pricing</h2>
          <p>Explore the possibilities with our customized app development services. Here are some types of applications we can create for you along with their estimated pricing.</p>
        </div>
        
        <div className="pricing-table">
          <table>
            <thead>
              <tr>
                <th>Application Type</th>
                <th>Estimated Price Range</th>
              </tr>
            </thead>
            <tbody>
              {appTypes.map((app, index) => (
                <tr key={index}>
                  <td>{app.type}</td>
                  <td>{app.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Link to="/contact" className='contact-button'>
          Contact Now
        </Link>
        </div>
      </div>
    );
  };
  
export default AppDevelopmentPricing;
