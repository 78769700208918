// HeroSection.js
import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import heroImage1 from '../assets/images/hero-section/1.png';
import heroImage2 from '../assets/images/hero-section/2.png';
import heroImage3 from '../assets/images/hero-section/3.png';
import heroImage4 from '../assets/images/hero-section/4.png';
import { Link } from 'react-router-dom';

const HeroSection = () => {
    const images = [heroImage1, heroImage2, heroImage3, heroImage4];
    const [currentImage, setCurrentImage] = useState(0);

    const imageCount = images.length;

    useEffect(() => {
        // Auto increment image index every 3 seconds
        const intervalId = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % imageCount);
        }, 3000);

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [imageCount]);

    return (
        <div className="hero-section">
            <div className="content">
                <h1>Get <span className='purple-gradient'>Digital-Solutions</span> for everything.</h1>
                <p>Take your business to the next-level with the help of our technologies, we at WebArtStudio provides solution to everything from website-development to social-media-marketing to AI-Integration, contact us for a meeting.
                </p>
                <div className='cards-container'>

                    <div className='card'>
                        <h3>Get a Website</h3>
                        <p>
                            Get a superfast dynamic website with secure backend.
                        </p>
                        <Link to="/website-development-pricing" className='btn-h'>
                            Explore
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>Social-Media-Marketing</h3>
                        <p>
                            Social Media Management & Organic Marketing Services.
                        </p>
                        <Link to="/social-media-marketing-pricing" className='btn-h'>
                            Explore
                        </Link>
                    </div>

                </div>
            </div>
            <div className="hero-image">
                <img src={images[currentImage]} alt=''/>
            </div>
        </div>
    );
};

export default HeroSection;
