// JoinLinkedInCommunitySection.js
import React from 'react';
import './JoinLinkedInCommunitySection.css';

const JoinLinkedInCommunitySection = () => {
  return (
    <section className="join-linkedin-community-section">
      <div className="content">
        <h2>Join Our LinkedIn Community</h2>
        <p>Stay updated with the latest industry trends, connect with professionals, and be part of our growing community on LinkedIn.</p>
        <a href="https://www.linkedin.com/company/webartstudio-online/" className="join-button" target="_blank" rel="noreferrer">Join Now</a>
      </div>
    </section>
  );
};

export default JoinLinkedInCommunitySection;
