// Contact.js
import React, { useState } from 'react';
import { FaPhone, FaEnvelope, FaMapMarker, FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import ContactForm from './ContactForm'; // Import the ContactForm component
import './Contact.css';

const Contact = () => {
  const [showForm, setShowForm] = useState(true);

  const handleFormToggle = () => {
    setShowForm(!showForm);
  };

  return (
    <div className="contact-section">
      <div className="contact-details">
        <h2>Contact Details</h2>
        <div className="contact-info">
          <p>
            <FaPhone /> Phone: +91-8399-834-759
          </p>
          <p>
            <FaEnvelope /> Email: webartstudio.online@gmail.com
          </p>
          <p>
            <FaMapMarker /> Location: Guwahati-13, Assam
          </p>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/webartstudio-online/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://www.instagram.com/webartstudio.online/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
          </div>
        </div>
      </div>

      {showForm && <ContactForm onSuccess={handleFormToggle} />}

      {!showForm && (
        <div className="success-message-container">
          <p className="success-message">Form submitted successfully!</p>
          <button onClick={handleFormToggle}>Submit Another</button>
        </div>
      )}
    </div>
  );
};

export default Contact;
