import React from 'react'
import HeroSection from '../components/HeroSection'
import Softwares from '../components/Softwares'
import Services from '../components/Services'
import WhyChooseUsSection from '../components/WhyChooseUsSection'
import JoinLinkedInCommunitySection from '../components/JoinLinkedInCommunitySection'
import Contact from '../components/Contact'
import Popup from '../components/Popup'

const Home = () => {
  return (
    <>
    <HeroSection/>
    <Services/>
    <JoinLinkedInCommunitySection/>
    <Softwares/>
    <WhyChooseUsSection/>
    <Contact/>
    <Popup/>
    </>
  )
}

export default Home

