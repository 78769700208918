// TeamMemberCard.js
import React from 'react';
import { FaLinkedin, FaInstagram, FaFacebook } from 'react-icons/fa';
import './About.css';

const TeamMemberCard = ({ name, image, designation, linkedin, instagram, facebook, avatar }) => {
  return (
    <div className="team-member-card">
      <img src={image} alt={`${image}'s Avatar`} className="avatar" />
      <h3>{name}</h3>
      <p>{designation}</p>
      <div className="social-icons">
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        <a href={instagram} target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href={facebook} target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
      </div>
    </div>
  );
};

export default TeamMemberCard;
