import React from 'react'
import './AppDevelopmentPricing.css';
import { Link } from 'react-router-dom';

const CustomizedSoftwares = () => {
  return (
    <div className="app-development-pricing-page">
    <div className="pricing-heading">
      <h2>Customized-Software-Development</h2>
      <p>Elevate your business with our custom app development solutions. Choose the package that best suits your needs and budget.</p>
    </div>
    
    <div className="pricing-table">
      <table>
        <thead>
          <tr>
            <th>Package</th>
            <th>Features</th>
            <th>Price (INR)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Basic</td>
            <td>
              <ul>
                <li>Basic Features</li>
                <li>Basic Database</li>
                <li>Simple UI</li>
              </ul>
            </td>
            <td>25,000</td>
          </tr>
          <tr>
            <td>Standard</td>
            <td>
              <ul>
                <li>Advanced Features</li>
                <li>Complicated Database</li>
                <li>Complex UI</li>
              </ul>
            </td>
            <td>40,000</td>
          </tr>
          <tr>
            <td>Premium</td>
            <td>
              <ul>
                <li>Artificial Intelligence</li>
                <li>Advanced Database</li>
                <li>Robust Applications</li>
              </ul>
            </td>
            <td>60,000</td>
          </tr>
        </tbody>
      </table>
      <Link to="/contact" className='contact-button'>
          Contact Now
        </Link>
    </div>
  </div>
  )
}

export default CustomizedSoftwares