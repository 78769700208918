import React from 'react';
import './SocialMediaPricing.css';
import { Link } from 'react-router-dom';

const SocialMediaPricing = () => {
  const packages = [
    {
      name: 'Basic',
      price: '5,000',
      features: ['SEO Optimization', 'Marketing Strategies','Content Calender', 'Content Creation', 'Cameraman Visit Once'],
    },
    {
      name: 'Standard',
      price: '10,000',
      features: ['Influencer Marketing', 'SEO Optimization', 'Marketing Strategies','Content Calender', 'Content Creation', 'Cameraman Visit Twice'],
    },
    {
      name: 'Premium',
      price: '15,000',
      features: ['Influencer Marketing', 'Advanced Data Analysis','Conduct a Event',  'SEO Optimization', 'Marketing Strategies','Content Calender', 'Content Creation', 'Cameraman Visit Twice'],
    },
  ];

  return (
    <div className="social-media-pricing-page">
      <div className="pricing-heading">
        <h2>Social Media Marketing Pricing</h2>
        <p>Elevate your brand's social presence with our tailored marketing packages. Choose the package that best suits your needs and budget.</p>
      </div>
      
      <div className="pricing-cards">
        {packages.map((pkg, index) => (
          <div className="pricing-card" key={index}>
            <h3>{pkg.name} Package</h3>
            <div className="price">{pkg.price} INR</div>
            <div className="features">
              {pkg.features.map((feature, idx) => (
                <p key={idx}>{feature}</p>
              ))}
            </div>
            <Link to="/contact" className='contact-button'>
          Contact Now
        </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaPricing;
