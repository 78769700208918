import React from 'react';
import { Link } from 'react-router-dom';
import '../components/Services.css';

const OurServicesSection = () => {
  const services = [
    {
      icon: '💻',
      title: 'Website Development',
      description: 'Crafting responsive and user-friendly websites tailored to your business needs.',
      route: '/website-development-pricing',
    },
    {
      icon: '📱',
      title: 'App Development',
      description: 'Building innovative and feature-rich mobile applications for various platforms.',
      route: '/app-development-pricing',
    },
    {
      icon: '📢',
      title: 'Social Media Marketing',
      description: 'Strategic social media campaigns to enhance your brand visibility and engagement.',
      route: '/social-media-marketing-pricing',
    },
    {
      icon: '🛠️',
      title: 'Customized Softwares',
      description: 'Developing bespoke software solutions to streamline your business operations.',
      route: '/customized-softwares-pricing',
    },
  ];

  return (
    <section className="our-services-section" id='services'>
      <h2>Our Services</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <Link to={service.route}>
              <button className='btn-s'>View Details</button>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurServicesSection;
