import React from 'react';
import './Projects.css'

const projects = [
  {
    id: 1,
    name: 'BrightCareers.in',
    imageUrl: '/projects/6.png',
    manager: 'Prandeep',
    link: 'https://brightcareers.in',
    developers: ['Prandeep']
  },
  {
    id: 2,
    name: 'TheHummingBird',
    imageUrl: '/projects/7.png',
    manager: 'Rittija',
    link: 'https://thehummingbird.org.in',
    developers: ['...']
  },
  {
    id: 3,
    name: 'Nishas Dental Care',
    imageUrl: '/projects/8.png',
    manager: 'Prandeep',
    link: 'https://nishasdentalcare.in',
    developers: ['...']
  },
  {
    id: 4,
    name: 'Jasingfa IAS Academy',
    imageUrl: '/projects/9.png',
    manager: 'Prandeep',
    link: 'https://jasingfaias.online',
    developers: ['...']
  },
  {
    id: 5,
    name: 'Madyantika Fashion',
    imageUrl: '/projects/10.png',
    manager: 'Prandeep',
    link: 'https://madyantika.in',
    developers: ['...']
  },
  {
    id: 6,
    name: 'FufuGadi',
    imageUrl: '/projects/11.png',
    manager: 'Prandeep',
    link: 'https://fufugadi.com',
    developers: ['...']
  },
  {
    id: 7,
    name: 'ECell AEC',
    imageUrl: '/projects/12.png',
    manager: 'Prandeep',
    link: 'https://edc.aec.ac.in',
    developers: ['...']
  },
  {
    id: 8,
    name: 'Srishti Cooperative Society',
    imageUrl: '/projects/15.png',
    manager: 'Prandeep',
    link: 'https://srishticooperativesociety.com',
    developers: ['...']
  },
  {
    id: 9,
    name: 'Vidyabarta Website',
    imageUrl: '/projects/13.png',
    manager: 'Prandeep',
    link: 'https://vidyabarta.com',
    developers: ['...']
  },
  {
    id: 10,
    name: 'Omnisync Technologies',
    imageUrl: '/projects/14.png',
    manager: 'Prandeep',
    link: 'https://omnisynctechnologies.com',
    developers: ['...']
  },

];

const ProjectCard = ({ project }) => {
  return (
   <div className='projects'>
     <div className="project-card">
      <img src={project.imageUrl} alt={project.name} className="project-image" />
      <div className="project-details">
        <h3>{project.name}</h3>
        <p>Project Managed By: {project.manager}</p>
        {/* <p>Developers: {project.developers.join(', ')}</p> */}
        <a href={project.link} className="view-project-btn" target='_blank' rel="noreferrer">View Project</a>
      </div>
    </div>
   </div>
  );
};

const ProjectPage = () => {
  return (
    <div className="project-page">
      {projects.map(project => (
        <ProjectCard key={project.id} project={project} />
      ))}
    </div>
  );
};

export default ProjectPage;
