// About.js
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './About.css';
import TeamMemberCard from './TeamMemberCard';


const About = () => {
  const teamMembers = [
    {
      name: 'Rittija Kashyap',
      designation: 'Marketing Lead',
      linkedin: 'https://www.linkedin.com/in/rittija-kashyap/',
      instagram: 'https://www.instagram.com/k_rittija/',
      facebook: 'https://www.facebook.com/',
      image: '/team/rittija.jpg'
    },
    {
      name: 'Prabal Pratim Das',
      designation: 'Graphic Designer',
      linkedin: 'https://www.linkedin.com/in/',
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Ne7oVV6Lx9uAnmJDUZrrLcGy8yzo1sXdpQ&s'
    },
    {
      name: 'Kaushik Borah',
      designation: 'Video Editor',
      linkedin: 'https://www.linkedin.com/',
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Ne7oVV6Lx9uAnmJDUZrrLcGy8yzo1sXdpQ&s'
    },
    {
      name: 'Abinash Kalita',
      designation: 'Android Developer',
      linkedin: 'https://www.linkedin.com/',
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Ne7oVV6Lx9uAnmJDUZrrLcGy8yzo1sXdpQ&s'
    },
    {
      name: 'Parijat Bhattacharjee',
      designation: 'AI Developer',
      linkedin: 'https://www.linkedin.com/',
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Ne7oVV6Lx9uAnmJDUZrrLcGy8yzo1sXdpQ&s'
    },
    {
      name: 'Prandeep Chutia',
      designation: 'Backend Developer',
      linkedin: 'https://www.linkedin.com/in/prandeep-chutia-5aa061228/',
      instagram: 'https://www.instagram.com/prandeep10',
      facebook: 'https://www.facebook.com/',
      image: '/team/prandeep.jpg'
    },
  ];

  return (
    <div className="about-section">
      <h2>About Us</h2>

      <Tabs>
        <TabList>
          <Tab>About</Tab>
          <Tab>Team</Tab>
          {/* Add more tabs as needed */}
        </TabList>

        <TabPanel>
          <p className='about-text'>Welcome to WebArtStudio, where innovation meets creativity. As a cutting-edge digital agency, we specialize in crafting compelling online experiences that elevate your brand to new heights. Our team of dedicated professionals is passionate about pushing boundaries and delivering tailor-made solutions for website development, app creation, social media marketing, and customized software development. <br />

            At WebArtStudio, we believe in the power of design and technology to transform ideas into impactful digital solutions. Our commitment to excellence, coupled with a keen understanding of the ever-evolving digital landscape, ensures that your brand not only stays relevant but thrives in the online world.
            <br />
            Join us on a journey of innovation, where every project is a canvas for artistic expression and technological prowess. Let's collaborate and bring your vision to life, making a lasting impact in the digital realm.
            <br />
            Discover the difference with WebArtStudio — where creativity knows no bounds, and success is only a click away.</p>
        </TabPanel>

        <TabPanel>
          <div className="team-members-container">
            {teamMembers.map((member, index) => (
              <TeamMemberCard key={index} {...member} />
            ))}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default About;
