// OurServicesSection.js
import React from 'react';
import './Services.css';

const OurServicesSection = () => {
  const services = [
    {
      icon: '💻',
      title: 'Website Development',
      description: 'Crafting responsive and user-friendly websites tailored to your business needs.',
    },
    {
      icon: '📱',
      title: 'App Development',
      description: 'Building innovative and feature-rich mobile applications for various platforms.',
    },
    {
      icon: '📢',
      title: 'Social Media Marketing',
      description: 'Strategic social media campaigns to enhance your brand visibility and engagement.',
    },
    {
      icon: '🛠️',
      title: 'Customized Softwares',
      description: 'Developing bespoke software solutions to streamline your business operations.',
    },
  ];

  return (
    <section className="our-services-section" id='services'>
      <h2>Our Services</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6013423249833366"
     crossorigin="anonymous"></script>
<ins class="adsbygoogle"
     data-ad-client="ca-pub-6013423249833366"
     data-ad-slot="8633778617"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>


    </section>
  );
};

export default OurServicesSection;
