import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa'; // Import the close icon from react-icons/fa
import './Popup.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    // Store the state in localStorage to keep it closed after page refresh
    localStorage.setItem('popupClosed', 'true');
  };

  // Check if popup has been closed before
  const popupClosed = localStorage.getItem('popupClosed');

  // Close the popup if clicked outside of it
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('popup-overlay')) {
      handleClose();
    }
  };

  // Render the popup only if it's open and hasn't been closed before
  return !popupClosed && isOpen ? (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="close-btn" onClick={handleClose}><FaTimes /></button>
        <h2>Special Offer</h2>
        <p>50% off on Website Development Services for month of April & May.</p>
      
        <Link to="/website-development-pricing">
        <button className="claim-btn">Claim Offer</button>
        </Link>
      </div>
    </div>
  ) : null;
};

export default Popup;
