// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import About from './pages/About';
import Contact from './pages/Contact';
import WebsitePricing from './pages/WebsitePricing';
import SocialMediaPricing from './pages/SocialMediaPricing';
import AppDevelopmentPricing from './pages/AppDevelopmentPricing';
import CustomizedSoftwares from './pages/CustomizedSoftwares';
import Footer from './components/Footer';
import Projects from './pages/Projects';
import CareersForm from './pages/Careers';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/careers" element={<CareersForm />} />
        <Route path="/website-development-pricing" element={<WebsitePricing />} />
        <Route path="/app-development-pricing" element={<AppDevelopmentPricing />} />
        <Route path="/social-media-marketing-pricing" element={<SocialMediaPricing />} />
        <Route path="/customized-softwares-pricing" element={<CustomizedSoftwares />} />
      </Routes>
      <Footer/>
    </Router>
    
  );
};

export default App;
