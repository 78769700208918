// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>Connect with Us</h3>
          <div className="social-links">
            <a href="https://www.linkedin.com/company/webartstudio-online/"><FaLinkedin /></a>
            <a href="https://www.instagram.com/webartstudio.online/"><FaInstagram /></a>
            <a href="https://www.linkedin.com/company/webartstudio-online/"><FaFacebook /></a>
            <a href="https://www.linkedin.com/company/webartstudio-online/"><FaTwitter /></a>

          </div>
        </div>
        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 WebArtStudio. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
