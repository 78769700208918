import React, { useState } from 'react';
import './Careers.css';

const CareersForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    skillset: '',
    institute: '',
    degree: '',
    graduation_year: '',
    message: '',
    resume: null
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    try {
      const response = await fetch('http://127.0.0.1:8000/api/career-form-data', {
        method: 'POST',
        body: formDataToSend
      });
      console.log(response); // Add this line to inspect the response
      const data = await response.json();
      console.log(data);
      // Optionally handle success (e.g., display a success message)
    } catch (error) {
      console.error('Error:', error);
      // Optionally handle error (e.g., display an error message)
    }
  };

  return (
    <div className="careers-container">
      <h1>Careers Page</h1>
      <form onSubmit={handleSubmit}>
        <label>Name:</label>
        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        <br />
        <label>Email:</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        <br />
        <label>Phone:</label>
        <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
        <br />
        <label>Skillset:</label>
        <textarea name="skillset" value={formData.skillset} onChange={handleChange}></textarea>
        <br />
        <label>Institute:</label>
        <input type="text" name="institute" value={formData.institute} onChange={handleChange} />
        <br />
        <label>Degree:</label>
        <input type="text" name="degree" value={formData.degree} onChange={handleChange} />
        <br />
        <label>Graduation Year:</label>
        <input type="number" name="graduation_year" value={formData.graduation_year} onChange={handleChange} />
        <br />
        <label>Message:</label>
        <textarea name="message" value={formData.message} onChange={handleChange}></textarea>
        <br />
        <label>Resume:</label>
        <input type="file" name="resume" onChange={handleFileChange} accept="application/pdf" />
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default CareersForm;
